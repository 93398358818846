<template>
  <main class="main">
    <div class="form-block">
      <h1>Contactez-nous</h1>
      <form id="myForm"></form>
    </div>

    <div class="block">
      <h3>Deepki France</h3>
      <span class="separator"></span>
      <p><svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14"><path fill="#fff" d="M5.148.011A5.83 5.83 0 015.505 0c3.04.005 5.5 2.372 5.495 5.288 0 3.558-3.086 6.905-4.636 8.375a1.27 1.27 0 01-1.725 0C3.097 12.211.037 8.885 0 5.35-.019 2.548 2.233.212 5.148.011M7.323 5c0-1.105-.818-2-1.828-2-1.01 0-1.828.895-1.828 2s.818 2 1.828 2c1.01 0 1.828-.895 1.828-2" class="svg"></path></svg>7, villa du Clos Malevart</p>
      <p style="margin-left: 24px;">75 011 Paris - FRANCE</p>
      <br><br>
      <a href="tel:+33146060919"><svg class="mr-1 mt-1" xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16"><path fill="#fff" d="M11.73 13.463l-1.618-1.99a1.705 1.705 0 00-2.365-.252l-.817.657a.485.485 0 01-.61-.033 13.772 13.772 0 01-3.199-5.732.439.439 0 01.296-.523l.991-.32c.887-.287 1.356-1.214 1.06-2.074L4.6.836C4.34.11 3.47-.21 2.773.145.148 1.527-1.452 4.528 1.886 10.462c3.34 5.934 6.817 6.238 9.443 4.855a1.219 1.219 0 00.4-1.854z" class="svg"></path></svg>+33 1 46 06 09 19</a>
      <span class="separator"></span>

      <ul class="menu-social white ul--reset">
        <li><a target="_blank" rel="noopener" href="https://www.linkedin.com/company/deepki/"><img src="/img/in.png" alt=""></a></li>
        <li><a target="_blank" rel="noopener" href="https://www.facebook.com/DeepkiSAS/"><img src="/img/fb.png" alt=""></a></li>
        <li><a target="_blank" rel="noopener" href="https://twitter.com/DeepkiSAS"><img src="/img/tw.png" alt=""></a></li>
      </ul>
    </div>
  </main>
</template>


<script>
// hbspt.forms.create({
//   region: "na1",
//   portalId: "19531822",
//   formId: "00f1d8db-5439-43bf-98ff-4f0c8834be4f"
// });

export default {
  name: "contact",
  mounted() {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "19531822",
      formId: "00f1d8db-5439-43bf-98ff-4f0c8834be4f",
      target: "#myForm"
    })
  },
  metaInfo: {
    meta: [{
      name: 'description',
      content: 'Vous souhaitez vous faire accompagner pour votre réponse au décret tertiaire ? Ou bien simplement échanger ? Nos experts sont à l’écoute !'
    }]
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.main {
  padding: 64px 0;
  justify-content: center;
  flex-direction: row;
  background-image: url("/img/contact.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 760px) {
    flex-direction: column;
  }
}

.block {
  min-width: 300px;
  max-width: 300px;
  display: block;
  padding: 24px;
  border-radius: 8px;
  color: white;

  h3 {
    font-size: 32px;
    color: white;
  }

  .separator {
    display: block;
    width: 55px;
    height: 2px;
    margin-top: 12px;
    background-color: white;
    margin-bottom: 18px;
  }

  p {
    margin-top: 8px;

    svg {
      margin-right: 12px;
    }
  }

  a {
    display: block; 
    text-decoration: none;
    color: white;
    font-size: 18px;
    
    svg {
      margin-right: 12px;
    }
  }

}

.form-block {
  display: block;
  padding: 24px;
  width: 100%;
  max-width: 760px;
  margin-right: 24px;
  background-color: #DEDEDE;
  border-radius: 8px;
  background: white;
  color: #3B3D3E;    
}

h1 {
  font-size: 42px;
  margin-bottom: 24px;
  font-weight: 700;
  text-align: center;
}

.svg {
  fill: white;
}

.menu-social {
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 48px;
    margin-right: 4px;

    &:hover {
      background-color: rgba(0,0,0, .2);
      border-radius: 50%;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/style/variables.scss";
.plezi-submit-btn {
  color: white;
  background-color: $secondary!important;
}

label {
  color: white!important;
}

input {
  outline: 0!important;
}

</style>
